import React from "react";
import MetricsCard from "./MetricsCard";
import PlayersDetailView from "./PlayersDetailView";

const Stats = (props) => {
  function calculateMetrics(data) {
    let totalUsers = 0;
    let totalCompletedChallenges = 0;
    let totalCurses = 0;

    let usersBeenCursed = {};
    let usersShuffledCards = {};
    let usersTakeABreakCurse = {};
    let mostCursesUid = null;
    let mostChallengesUid = null;

    let totalBeenCursed = 0;
    let totalShuffledCards = 0;
    let totalTakeABreakCurse = 0;
    let totalInconvenienced = 0;
    let totalWentToParliamentCurse = 0;
    let totalVetoSingleChallenge = 0;

    for (let uid in data) {
      totalUsers++;

      if (data[uid].CompletedChallenge) {
        totalCompletedChallenges += data[uid].CompletedChallenge;
      }

      if (data[uid].HasCursed) {
        totalCurses += data[uid].HasCursed;
      }

      if (data[uid].HasBeenCursed) {
        usersBeenCursed[uid] = data[uid].HasBeenCursed;
      }

      if (data[uid].ShuffledCards) {
        usersShuffledCards[uid] = data[uid].ShuffledCards;
      }

      if (data[uid].TakeABreakCurse) {
        usersTakeABreakCurse[uid] = data[uid].TakeABreakCurse;
      }

      // Determine player with most curses
      if (
        mostCursesUid === null ||
        (data[uid].HasCursed &&
          data[uid].HasCursed > (data[mostCursesUid].HasCursed || 0))
      ) {
        mostCursesUid = uid;
      }

      // Determine player with most challenges completed
      if (
        mostChallengesUid === null ||
        (data[uid].CompletedChallenge &&
          data[uid].CompletedChallenge >
            (data[mostChallengesUid].CompletedChallenge || 0))
      ) {
        mostChallengesUid = uid;
      }

      if (data[uid].HasBeenCursed) {
        totalBeenCursed += data[uid].HasBeenCursed;
      }

      if (data[uid].ShuffledCards) {
        totalShuffledCards += data[uid].ShuffledCards;
      }

      if (data[uid].TakeABreakCurse) {
        totalTakeABreakCurse += data[uid].TakeABreakCurse;
      }

      if (data[uid].Inconvenienced) {
        totalInconvenienced += data[uid].Inconvenienced;
      }

      if (data[uid].WentToParliamentCurse) {
        totalWentToParliamentCurse += data[uid].WentToParliamentCurse;
      }

      if (data[uid].VetoSingleChallenge) {
        totalVetoSingleChallenge += data[uid].VetoSingleChallenge;
      }
    }

    return {
      totalUsers: totalUsers,
      averageCompletedChallenges: totalCompletedChallenges / totalUsers,
      totalCurses: totalCurses,
      usersBeenCursed: usersBeenCursed,
      usersShuffledCards: usersShuffledCards,
      usersTakeABreakCurse: usersTakeABreakCurse,
      mostCurses: {
        uid: mostCursesUid,
        count: data[mostCursesUid]?.HasCursed || 0,
      },
      mostChallengesCompleted: {
        uid: mostChallengesUid,
        count: data[mostChallengesUid]?.CompletedChallenge || 0,
      },
      totalBeenCursed: totalBeenCursed,
      totalShuffledCards: totalShuffledCards,
      totalInconvenienced: totalInconvenienced,
      totalWentToParliamentCurse: totalWentToParliamentCurse,
      totalTakeABreakCurse: totalTakeABreakCurse,
      totalVetoSingleChallenge: totalVetoSingleChallenge,
    };
  }

  return (
    <div
      style={{
        padding: "2rem",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <MetricsCard
        metrics={calculateMetrics(props.game.stats)}
        players={props.game.players}
      />
      <div style={{ marginTop: "2rem" }}>
        <PlayersDetailView
          data={props.game.stats}
          players={props.game.players}
        />
      </div>
    </div>
  );
};

export default Stats;
