import firebase from "firebase/compat/app";
import { getDatabase, ref, set, get, update } from "firebase/database";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";
import pica from "pica";

const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
});

export const auth = app.auth();
export const functions = firebase.functions(); // Ensure this line is added

function generateRandomCode() {
  var code = "";
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  var charactersLength = characters.length;

  for (var i = 0; i < 4; i++) {
    var randomIndex = Math.floor(Math.random() * charactersLength);
    code += characters.charAt(randomIndex);
  }

  return code;
}

export function writeUserData(uid, email, name, phone) {
  const db = getDatabase();
  const reference = ref(db, "users/" + uid);
  set(reference, {
    email: email,
    name: name,
    phone: phone,
    game: "false",
    started: "false",
  });
}

export async function getUserData(userId) {
  const db = getDatabase();
  const reference = ref(db, "users/" + userId);
  const snapshot = await get(reference);
  const data = snapshot.val();
  return data;
}

export async function inGame(userId) {
  const db = getDatabase();
  const reference = ref(db, "users/" + userId + "/game");
  const snapshot = await get(reference);
  const data = snapshot.val();
  return data;
}

export async function isGameStarted(code) {
  const db = getDatabase();
  const reference = ref(db, "games/");
  const snapshot = await get(reference);
  const data = snapshot.val();

  let res = Object.keys(data).includes(code);

  if (res === true) {
    const reference = ref(db, "games/" + code + "/host/started/");
    const snapshot = await get(reference);
    const data = snapshot.val();
    if (data === "true") {
      res = "started";
    }
  }

  return res;
}

export function createGame(uid, username) {
  const db = getDatabase();
  let code = generateRandomCode();
  let reference = ref(db, "users/" + uid);
  update(reference, {
    game: code,
  });

  reference = ref(db, "games/" + code + "/players/" + uid);
  set(reference, {
    coins: 0,
    gems: 0,
    username,
  });

  reference = ref(db, "games/" + code + "/host");
  set(reference, {
    host: uid,
    started: "false",
    endTime: "false",
  });
}

export function joinGame(uid, username, code) {
  const db = getDatabase();
  let reference = ref(db, "users/" + uid);
  update(reference, {
    game: code,
  });

  reference = ref(db, "games/" + code + "/players/" + uid);
  update(reference, {
    coins: 0,
    gems: 0,
    username,
  });
}

export function cancelGMRequest(uid, username, code) {
  const db = getDatabase();
  let reference = ref(db, "users/" + uid);
  update(reference, {
    game: "false",
  });

  const reference2 = ref(db, "games/" + code + "/gameMasterRequests/" + uid);
  set(reference2, null);
}

export function joinGameAsGM(uid, username, code, phone) {
  const db = getDatabase();
  console.log("updating", uid, username, code);
  let reference = ref(db, "users/" + uid);
  update(reference, {
    game: code,
  });

  reference = ref(db, "games/" + code + "/gameMasterRequests/" + uid);
  update(reference, {
    username,
    uid,
    phone,
  });
}

export function startGame(code, endTime, pack, hideMins) {
  const db = getDatabase();

  let gameRef = ref(db, "games/" + code + "/host");
  console.log("gameRef", gameRef);
  update(gameRef, {
    started: "true",
    endTime: endTime.toString(),
    pack: pack,
    hideLeaderboardMins: hideMins,
  });
  // let userRef = ref(db, "users/" + user + "/host");
  // console.log(userRef)
  // update(userRef, {
  //   started: "true",
  //   endTime: endTime.toString(),
  //   pack: pack
  // });
}

export function deleteGame(uid, gameId) {
  const db = getDatabase();
  const reference = ref(db, "users/" + uid);
  update(reference, {
    game: "false",
  });

  const reference2 = ref(db, "games/" + gameId);
  set(reference2, null);
}

export async function getPreviousGames(uid) {
  const db = getDatabase();

  // Reference to the list of all previous games for the user
  const previousGamesReference = ref(db, "users/" + uid + "/previousGames/");

  // Fetch the data from the database
  const previousGamesSnapshot = await get(previousGamesReference);
  const previousGamesData = previousGamesSnapshot.val();

  if (!previousGamesData) {
    return [];
  }

  // Convert the data object into an array and fetch the game by its position
  const previousGamesArray = Object.values(previousGamesData);

  console.log(previousGamesArray);

  return previousGamesArray;
}
export async function moveGameToPrevious(uid, gameId) {
  const db = getDatabase();

  // Check if the game has started or not
  const gameStartedReference = ref(db, "games/" + gameId + "/host/started");
  const gameStartedSnapshot = await get(gameStartedReference);
  const gameStarted = gameStartedSnapshot.val();

  // If the game has not started (started value is "false"), delete the game and return
  if (gameStarted === "false") {
    const gameReference = ref(db, "games/" + gameId);
    await set(gameReference, null);
    return;
  }

  // Step 1: Get the list of players for the game
  const playersReference = ref(db, "games/" + gameId + "/players");
  const playersSnapshot = await get(playersReference);
  const playersData = playersSnapshot.val();

  if (playersData) {
    for (const playerId in playersData) {
      // Step 2: Delete upcomingChallenges for each player
      const upcomingChallengesReference = ref(
        db,
        "games/" + gameId + "/players/" + playerId + "/upcomingChallenges"
      );
      await set(upcomingChallengesReference, null);
      const currentChallengesReference = ref(
        db,
        "games/" + gameId + "/players/" + playerId + "/currentChallenges"
      );
      await set(currentChallengesReference, null);
      const completedChallengesReference = ref(
        db,
        "games/" + gameId + "/players/" + playerId + "/completedChallenges"
      );
      await set(completedChallengesReference, null);
    }
  }

  // Step 3: Get the game data
  const gameReference = ref(db, "games/" + gameId);
  const gameSnapshot = await get(gameReference);
  const gameData = gameSnapshot.val();

  if (gameData) {
    // Generate a unique identifier for the previous game. Using a timestamp for simplicity.
    const uniqueId = Date.now().toString();

    // Write the game data to the new path
    const previousGameReference = ref(
      db,
      "users/" + uid + "/previousGames/" + uniqueId
    );
    await set(previousGameReference, gameData);

    // Remove the game data from the original path
    await set(gameReference, null);
  }

  // Update the user's game status
  const userReference = ref(db, "users/" + uid);
  await update(userReference, {
    game: "false",
  });
}

export function leaveGame(uid, gameId) {
  console.log("running this here leave");
  const db = getDatabase();
  const reference = ref(db, "users/" + uid);
  update(reference, {
    game: "false",
  });

  const reference2 = ref(db, "games/" + gameId + "/players/" + uid);
  set(reference2, null);
}

export function getDB() {
  return getDatabase();
}

// export async function uploadImageToStorage(uid, imageFile) {
//   const storage = firebase.storage();
//   const storageRef = storage.ref();
//   const userImageRef = storageRef.child(`user_images/${uid}`);

//   try {
//     // Upload the image file to Firebase Storage
//     const snapshot = await userImageRef.put(imageFile);
//     const downloadUrl = await snapshot.ref.getDownloadURL();

//     // Update the user data with the image URL
//     const db = getDatabase();
//     const reference = ref(db, "users/" + uid);
//     update(reference, {
//       image: downloadUrl,
//     });

//     console.log("Image uploaded and user data updated successfully.");
//   } catch (error) {
//     console.error("Error uploading image to Firebase Storage:", error);
//   }
// }

export async function uploadImageToStorage(uid, imageFile) {
  const storage = firebase.storage();
  const storageRef = storage.ref();
  const userImageRef = storageRef.child(`user_images/${uid}`);

  try {
    // Create an offscreen canvas
    const offScreenCanvas = document.createElement("canvas");
    const img = new Image();

    // Read the image file
    const reader = new FileReader();
    reader.readAsDataURL(imageFile);
    await new Promise((resolve, reject) => {
      reader.onload = (e) => {
        img.src = e.target.result;
        img.onload = () => resolve();
        img.onerror = reject;
      };
    });

    // Set the canvas size (you can adjust the quality by changing the size)
    offScreenCanvas.width = img.width / 2; // Example: reduce size to 50%
    offScreenCanvas.height = img.height / 2;

    // Resize the image using pica
    await pica().resize(img, offScreenCanvas);

    // Convert the canvas to a blob
    const compressedImage = await pica().toBlob(
      offScreenCanvas,
      "image/jpeg",
      0.8
    ); // Adjust the quality parameter (0.8) as needed

    // Upload the compressed image file to Firebase Storage
    const snapshot = await userImageRef.put(compressedImage);
    const downloadUrl = await snapshot.ref.getDownloadURL();

    // Update the user data with the image URL
    const db = getDatabase();
    const reference = ref(db, "users/" + uid);
    update(reference, {
      image: downloadUrl,
    });

    console.log(
      "Compressed image uploaded and user data updated successfully."
    );
  } catch (error) {
    console.error(
      "Error uploading compressed image to Firebase Storage:",
      error
    );
  }
}

export function approveGM(uid, gameId, approved) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    uid,
    gameId,
    approved,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch(
    "https://updateGameMasterStatus-nykygdtd7a-uc.a.run.app",
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.log("error", error));
}

export default app;
