import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import { ref, onValue } from "firebase/database";
import {
  getDB,
  createGame,
  leaveGame,
  moveGameToPrevious,
  getUserData,
} from "../../../firebase";
import { Alert, Snackbar, Typography } from "@mui/material";
import {
  RedGameLoadingButton,
  RoundedCard,
} from "../../MUI_Components/Components";
import AccountMenu from "../AccountMenu";
import dayjs from "dayjs";
import "../Pages.css";
import ResultsPage from "./ResultsPage";
import { DeleteDialog, LeaveGameDialog } from "./ProfileDialogs";
import { getTimestampIn5Hours } from "../../../utils/utils";
import AvatarContainer from "./AvatarContainer";
import MainAvatar from "./Components/MainAvatar";
import NewGame from "./Components/NewGame";
import ProfilePageGM from "./Components/ProfilePageGM";
import GameConfiguration from "./Components/GameConfiguration";

const Profile = (props) => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const { currentUser, logout } = useAuth();
  const [userDetails, setUserDetails] = useState(null);
  const [host, setHost] = useState();
  const [open, setOpen] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [leaveGameOpen, setLeaveGameOpen] = useState(false);
  const [userImage, setUserImage] = useState(null);
  const [endTime, setEndTime] = useState();
  const [hideLeaderboardMins, setHideLeaderboardMins] = useState();
  const [loading, setLoading] = useState(false);
  const [packList, setPackList] = useState();
  const [loaded, setLoaded] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [openToast, setOpenToast] = useState(false);

  const handleClickToast = () => {
    const textToCopy = props.user.game;
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          setOpenToast(true);
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    } else {
      // Fallback for older browsers
      try {
        const textarea = document.createElement("textarea");
        textarea.value = textToCopy;
        textarea.setAttribute("readonly", ""); // Make textarea read-only
        textarea.style.position = "absolute"; // Position off-screen
        textarea.style.left = "-9999px"; // Move off-screen
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
        setOpenToast(true);
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }
    }
  };

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
  };
  // const [members, setMembers] = useState([]);

  const [value, setValue] = useState(dayjs(getTimestampIn5Hours()));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickLeaveGameOpen = () => {
    setLeaveGameOpen(true);
  };

  const handleLeaveGameClose = () => {
    setLeaveGameOpen(false);
  };

  useEffect(() => {
    if (props?.game?.host?.started !== "false") {
      setGameStarted(true);
    }
  }, [props.game.host]);

  useEffect(() => {
    const query = ref(getDB(), "/packs/");
    return onValue(query, (snapshot) => {
      if (snapshot.exists()) {
        setPackList(Object.entries(snapshot.val()));
      }
    });
  }, []);

  const handleLeaveGame = useCallback(async () => {
    setLoading(true);
    // setMembers([]);
    setHost(null);
    props.clearChallenges();
    setGameStarted(false);
    setLeaveGameOpen();
    setError("");
    try {
      leaveGame(currentUser.uid, props.user.game);
      props.setGame([]);
    } catch {
      setError("Failed to Leave Game");
    }
  }, [currentUser.uid, props]);

  useEffect(() => {
    if (props?.user?.game !== "false" && props?.user?.game !== undefined) {
      const query = ref(getDB(), "/games/" + props.user.game + "/host");
      return onValue(query, (snapshot) => {
        const data = snapshot.val();
        if (data === null) {
          handleLeaveGame();
          props.setGame([]);
        }
        if (snapshot.exists()) {
          setHost(data.host);
          setEndTime(data.endTime);
          setHideLeaderboardMins(data.hideLeaderboardMins);
        }
      });
    }
  }, [props.user.game, props, handleLeaveGame]);

  async function handleCreateGame() {
    setError("");
    setHost(currentUser.uid);
    try {
      await createGame(currentUser.uid, props.user.name);
    } catch {
      setError("Failed to Create Game");
    }
  }

  async function handleDeleteGame() {
    handleClose();
    props.clearChallenges();
    setError("");
    try {
      await moveGameToPrevious(currentUser.uid, props.user.game);
      props.setGame([]);
      setGameStarted(false);
    } catch {
      setError("Failed to Delete Game");
    }
  }

  async function handleLogout() {
    setError("");
    try {
      await logout();
      navigate("/login");
    } catch {
      setError("Failed to Log Out");
    }
  }

  function handleUpdateProfile() {
    navigate("/update-profile");
  }

  async function getUserDetails() {
    try {
      const userData = await getUserData(currentUser.uid);
      setUserDetails(userData);
      setUserImage(userData.image);
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
    }
  }

  useEffect(() => {
    getUserDetails().then((res) => {
      setLoaded(true);
    });
  }, []);

  function handleViewResults() {
    console.log("results");
    setShowResults(true);
  }

  return (
    loaded && (
      <>
        <div className="settingsContainer">
          {userDetails && userDetails.name && (
            <AccountMenu
              username={userDetails.name.charAt(0)}
              handleUpdateProfile={handleUpdateProfile}
              handleLogout={handleLogout}
              userImage={userImage}
            />
          )}
        </div>
        {props && (
          <div className="profileMainContainer">
            <div className="profileMain">
              <div className="profilePicture">
                <MainAvatar userImage={userImage} userDetails={userDetails} />
              </div>

              <RoundedCard className="profilePaper">
                <div className="paperBottom">
                  <div className="profileItems">
                    {error && (
                      <Alert severity="error" sx={{ marginBottom: "20px" }}>
                        {error}
                      </Alert>
                    )}

                    {props.user.game === "false" && (
                      <NewGame
                        setError={setError}
                        user={props.user}
                        handleCreateGame={handleCreateGame}
                        role={props.role}
                      />
                    )}
                    {props.user.game !== "false" && (
                      <div>
                        <Typography
                          variant="subtitle2"
                          sx={{ textAlign: "center" }}
                        >
                          Game Code
                        </Typography>
                        <Typography
                          variant="h3"
                          sx={{ textAlign: "center", marginBottom: "20px" }}
                          onClick={handleClickToast}
                          style={{ cursor: "pointer" }} // Optional: changes the cursor on hover
                        >
                          {props.user.game}
                        </Typography>
                      </div>
                    )}

                    <AvatarContainer user={props.user} />

                    <GameConfiguration
                      user={props.user}
                      host={host}
                      value={value}
                      endTime={endTime}
                      hideLeaderboardMins={hideLeaderboardMins}
                      game={props.game}
                      gameStarted={gameStarted}
                      gameEnded={props.gameEnded}
                      setGameEnded={() => {
                        props.setGameEnded();
                      }}
                      setValue={setValue}
                      packList={packList}
                      handleViewResults={handleViewResults}
                      handleClickOpen={handleClickOpen}
                      setError={setError}
                      gameStatus={props.gameStatus}
                    />

                    {/* <ProfilePageGM
                      user={props.user}
                      host={host}
                      gameStarted={gameStarted}
                      gameEnded={props.gameEnded}
                      gameStatus={props.gameStatus}
                      endTime={endTime}
                      isGameMaster={props.isGameMaster}
                      handleClickLeaveGameOpen={handleClickLeaveGameOpen}
                    /> */}
                    {props?.game?.host &&
                      currentUser?.uid !== host &&
                      props.gameStatus === "false" && (
                        <RedGameLoadingButton
                          fullWidth
                          variant="contained"
                          onClick={handleClickLeaveGameOpen}
                          sx={{ marginTop: "10px" }}
                          color="error"
                          loading={loading}
                        >
                          Leave Game
                        </RedGameLoadingButton>
                      )}
                    {props?.game?.host &&
                      currentUser?.uid !== host &&
                      props.gameStatus === "ended" && (
                        <>
                          <Typography variant="h4">Game Over!</Typography>
                          <Typography variant="h7">
                            Host has results.
                          </Typography>
                        </>
                      )}
                  </div>
                </div>
              </RoundedCard>
            </div>
          </div>
        )}

        {showResults && <ResultsPage setShowResults={setShowResults} />}

        <DeleteDialog
          open={open}
          handleClose={handleClose}
          handleDeleteGame={handleDeleteGame}
        />
        <LeaveGameDialog
          leaveGameOpen={leaveGameOpen}
          handleLeaveGameClose={handleLeaveGameClose}
          handleLeaveGame={handleLeaveGame}
        />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "left" }} // Positioning the Snackbar
          open={openToast}
          sx={{ width: "150px" }}
          autoHideDuration={6000}
          onClose={handleCloseToast}
        >
          <Alert
            onClose={handleCloseToast}
            severity="success"
            sx={{ width: "100%" }}
          >
            Copied!
          </Alert>
        </Snackbar>
      </>
    )
  );
};

export default Profile;
