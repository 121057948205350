import React from "react";
import "../Pages.css"; // Create a CSS file for the animations
import gm from "../../../assets/GM_Logo.png";
import { Typography } from "@mui/material";
const GameMasterSplash = () => {
  return (
    <div className="gameOverScreen">
      <div className="crownImageContainer">
        <img className="crownImage" src={gm} alt="GM Logo" />
      </div>
      <br />
      <Typography sx={{fontFamily: "inherit", paddingLeft: "10px", paddingRight: "10px", color: "white"}} variant="h3">You are the Game Master</Typography>
    </div>
  );
};

export default GameMasterSplash;
