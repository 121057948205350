import React, { useState } from "react";
import { CircularProgress, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import { green } from "@mui/material/colors";
import gm_logo from "../../assets/GM_Logo.png";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import coins from "../../assets/coins.png";
import "../DashboardPages/Pages.css";

const ColorButton = styled(Button)(({ theme }) => ({
  color: "white",
  borderRadius: "15px",
  height: "40px",
  fontSize: "20px",
  width: "250px",
  marginTop: "15px",
  backgroundColor: green[600],
  "&:hover": {
    backgroundColor: green[700],
  },
}));

const ChallengeCard = (props) => {
  const [loading, setLoading] = useState(false);
  return (
    <div className="ChallengeCardContainerGM">
      {loading === props.challenge.title ? (
        <div className="LoadingOverCard">
          <CircularProgress />
        </div>
      ) : null}
      {props.challenge.description.includes(":-:GM") && (
        <img src={gm_logo} alt="GM LOGO" className="gmLogo" />
      )}
      <div
        className={`ChallengeCardGM ${
          loading === props.challenge.title ? "loadingChallenge" : ""
        }`}
      >
        <Typography
          variant="h6"
          sx={{ marginBottom: "3px", fontWeight: "bold" }}
        >
          {props.challenge.title}
        </Typography>
        <div className="rewardContainer">
          <img src={coins} alt="coinsIcon" style={{ height: "30px" }} />
          <Typography variant="h5">{props.challenge.value}</Typography>
          {props.challenge.description.includes(":-:MP") && (
            <PeopleIcon sx={{ height: "auto", marginLeft: "20px" }} />
          )}
          {props.challenge.description.includes(":-:SP") && (
            <PersonIcon sx={{ height: "auto", marginLeft: "20px" }} />
          )}
        </div>
        <Typography variant="subtitle2">
          {props.challenge.description
            .replace(":-:GM", "")
            .replace(":-:MP", "")
            .replace(":-:SP", "")}
        </Typography>
        {props.approved && (
          <div className="buttonContainer">
            <ColorButton
              variant="contained"
              color="primary"
              onClick={() => {
                setLoading(props.challenge.challengeTitle);
                props.approveChallenge(props.challenge.challengeId).then(() => {
                  setLoading(false);
                });
              }}
            >
              Approve
            </ColorButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChallengeCard;
