import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Badge, Paper } from "@mui/material";
import "./ControlGame.css";
import { onValue, ref } from "firebase/database";
import { getDB } from "../../firebase";
import ControlGameUserTab from "./ControlGameUserTab";
import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import WaitingHost from "./WaitingHost";
import Stats from "./ControlPageSegments/Stats";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LeaderboardGM from "./ControlPageSegments/LeaderboardGM";
import CountdownTimer from "../DashboardPages/ProfilePage/Components/CountdownTimer";
import { GameButtonCustom } from "../MUI_Components/Components";
const ControlGame = (props) => {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const [game, setGame] = useState(null);
  const [players, setPlayers] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function convertObjectToArrayWithId(obj) {
    return Object.keys(obj).map((key) => ({
      ...obj[key],
      uid: key,
    }));
  }

  function unapprovedChallengesCount(data) {
    let count = 0;

    // Check if the 'completedChallenges' field exists in the data
    if (data.completedChallenges) {
      for (let challengeId in data.completedChallenges) {
        // Check if the challenge is not approved by GM
        if (!data.completedChallenges[challengeId].approvedByGM) {
          count++;
        }
      }
    }

    return count;
  }

  useEffect(() => {
    const query = ref(getDB(), "/games/" + props.game);
    console.log("game", props.game);
    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      console.log("data123", data);
      if (snapshot.exists()) {
        setGame(data);
        setPlayers(convertObjectToArrayWithId(data.players));
      }
    });
  }, [props.game]);

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        className="tabpanel"
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }

  return (
    <div >
      <Paper
        className="controlGamePaper"
        backgroundColor={"transparent"}
        sx={{ backgroundColor: "transparent" }}
      >
        {game && (
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="game master tabs"
                sx={{ backgroundColor: "white" }}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  icon={<HomeIcon />}
                  iconPosition="start"
                  label={
                    <div
                      style={{
                        position: "relative",
                        display: "inline-block",
                      }}
                    >
                      Home
                    </div>
                  }
                  {...a11yProps(0)}
                  key={"tabs" + 0}
                />
                {players.map((player, index) => {
                  return (
                    <Tab
                      label={
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                        >
                          {player.username}
                          {unapprovedChallengesCount(player) > 0 ? (
                            <Badge
                              badgeContent={unapprovedChallengesCount(player)}
                              color="error"
                              style={{
                                position: "absolute",
                                top: "1%",
                                left: "100%",
                              }}
                            />
                          ) : null}
                          {player?.curses?.major &&
                          Object.values(player?.curses?.major).length > 0 ? (
                            <Badge
                              badgeContent={
                                Object.values(player?.curses?.major).length
                              }
                              color={"primary"}
                              sx={{
                                ".MuiBadge-badge": {
                                  backgroundColor: "purple",
                                },
                                position: "absolute",
                                top: "1%",
                                left: "90%",
                              }}
                            />
                          ) : null}
                        </div>
                      }
                      {...a11yProps(index + 1)}
                      key={"tabs" + (index + 1)}
                    />
                  );
                })}
              </Tabs>
            </Box>

            <CustomTabPanel
              value={value}
              index={0}
              key={"tabPanel" + 0}
              backgroundColor={"transparent"}
              overflowX={"hidden"}
            >
              <GameButtonCustom
                variant="contained"
                sx={{ float: "right", marginTop: "0px" }}
                startIcon={<ArrowBackIcon />}
                onClick={() => {
                  navigate("/");
                }}
              >
                Player UI
              </GameButtonCustom>
              {game.host?.endTime && game.host?.started === "true" && (
                <div style={{ padding: "2rem", paddingTop: "50px" }}>
                  <Paper
                    elevation={3}
                    style={{ margin: "0 auto", marginTop: 10 }}
                  >
                    <CountdownTimer endTime={game.host.endTime} />
                  </Paper>
                </div>
              )}
              {game.stats && <LeaderboardGM game={props.game} />}

              {game.stats && <Stats game={game} />}
              {!game.stats && <WaitingHost className="waiting" />}
            </CustomTabPanel>
            {players.map((player, index) => {
              return (
                <CustomTabPanel
                  value={value}
                  index={index + 1}
                  key={"tabPanel" + index + 1}
                  backgroundColor={"transparent"}
                  overflowX={"hidden"}
                >
                  <ControlGameUserTab
                    game={game}
                    player={player}
                    code={props.game}
                    backgroundColor={"transparent"}
                  />
                </CustomTabPanel>
              );
            })}
          </Box>
        )}
      </Paper>
    </div>
  );
};

export default ControlGame;
