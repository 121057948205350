export function convertObjectToArrayWithId(obj) {
  return Object.keys(obj).map((key) => ({
    ...obj[key],
    challengeId: parseInt(key),
  }));
}

export function convertObjectToArrayWithIdBase(obj) {
  return Object.keys(obj).map((key) => ({
    ...obj[key],
  }));
}
export function convertObjectToArray(inputObj) {
  let resultArray = [];

  for (const [key, value] of Object.entries(inputObj)) {
    let newObj = {
      name: key,
      coins: value.coins,
    };
    resultArray.push(newObj);
  }
  resultArray.sort((a, b) => b.coins - a.coins);

  return resultArray;
}

export function convertCurseObjectToArrayWithId(obj) {
  return Object.keys(obj).map((key) => ({
    ...obj[key],
    curseUniqueId: key,
  }));
}

export async function endGame(game) {
  const endpoint = "https://endgame-nykygdtd7a-uc.a.run.app";

  const data = {
    gameId: game,
  };

  fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

export const DateComparison = (dateString, game) => {
  const currentDate = new Date();
  let endTime = new Date(dateString);

  if (currentDate > endTime) {
    endGame(game);
  }
  return {
    endTime: endTime.setMinutes(endTime.getMinutes() - 30),
    currenTime: currentDate,
  };
};

export function isUidApproved(array, uid) {
  const matchingItem = array.find((item) => item.uid === uid);
  return matchingItem && matchingItem.status === "approved";
}

export function delay(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export function getOrdinal(n) {
  var s = ["th", "st", "nd", "rd"],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

export const getTimestampIn5Hours = () => {
  const now = new Date();
  now.setHours(now.getHours() + 5);
  return now.toString();
};

export function futureTime(hours, minutes) {
  // Get the current date and time
  let now = new Date();

  // Add the specified hours and minutes to the current time
  now.setHours(now.getHours() + hours);
  now.setMinutes(now.getMinutes() + minutes);

  // Convert the date to the specified format
  let formattedTime = now.toUTCString();

  return formattedTime;
}

export const checkEndTime = (timestamp) => {
  const currentTime = new Date().getTime();
  let endTime = new Date(timestamp);
  return currentTime >= endTime;
};
export const checkLeaderboardTime = (timestamp, minutesBeforeEnd) => {
  const currentTime = new Date().getTime();
  let endTime = new Date(timestamp);

  const timeBeforeEnd = new Date(endTime - minutesBeforeEnd * 60000).getTime();
  return currentTime >= timeBeforeEnd && currentTime < endTime;
};

export function timeInWords(totalMinutes) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  let timeString = "";

  if (hours > 0) {
    timeString += `${hours} ${hours === 1 ? "hour" : "hours"}`;
  }

  if (minutes > 0) {
    if (hours > 0) {
      timeString += " and ";
    }
    timeString += `${minutes} ${minutes === 1 ? "minute" : "minutes"}`;
  }

  return timeString || "0 minutes";
}

export function getLatestTimestamp(data) {
  let latestTimestamp = 0;

  for (const key in data) {
      if (data[key].timestamp > latestTimestamp) {
          latestTimestamp = data[key].timestamp;
      }
  }

  return latestTimestamp;
}