import React from "react";
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

function MetricsCard({ metrics, players }) {
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="div">
          User Metrics
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="Total Users"
              secondary={metrics.totalUsers}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Average Completed Challenges"
              secondary={metrics.averageCompletedChallenges.toFixed(2)}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Total Curses"
              secondary={metrics.totalCurses}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Total Players Been Cursed"
              secondary={metrics.totalBeenCursed}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Total Shuffled Cards Powerups"
              secondary={metrics.totalShuffledCards}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Total Take a Break Curse Curses"
              secondary={metrics.totalTakeABreakCurse}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Player With Most Curses"
              secondary={`${players[metrics.mostCurses.uid].username} (Count: ${
                metrics.mostCurses.count
              })`}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Player With Most Challenges Completed"
              secondary={`${
                players[metrics.mostChallengesCompleted.uid].username
              } (Count: ${metrics.mostChallengesCompleted.count})`}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Total Inconvenienced Curses"
              secondary={metrics.totalInconvenienced}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Total Went to Parliament Curses"
              secondary={metrics.totalWentToParliamentCurse}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Total Veto Single Challenge Powerups"
              secondary={metrics.totalVetoSingleChallenge}
            />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
}

export default MetricsCard;
