import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";

function PlayersDetailView({ data, players }) {
  return (
    <TableContainer component={Paper}>
      <Typography variant="h6" component="div" style={{ padding: "1rem" }}>
        Detailed User Metrics
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Player</TableCell>
            <TableCell align="right">Completed Challenges</TableCell>
            <TableCell align="right">Has Cursed</TableCell>
            <TableCell align="right">Been Cursed</TableCell>
            <TableCell align="right">Shuffled Cards</TableCell>
            <TableCell align="right">Take a Break Curse</TableCell>
            <TableCell align="right">Inconvenienced</TableCell>
            <TableCell align="right">Went To Parliament Curse</TableCell>
            <TableCell align="right">Veto Single Challenge</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(data).map((uid) => (
            <TableRow key={uid}>
              <TableCell component="th" scope="row">
                {players[uid].username}
              </TableCell>
              <TableCell align="right">
                {data[uid].CompletedChallenge || 0}
              </TableCell>
              <TableCell align="right">{data[uid].HasCursed || 0}</TableCell>
              <TableCell align="right">
                {data[uid].HasBeenCursed || 0}
              </TableCell>
              <TableCell align="right">
                {data[uid].ShuffledCards || 0}
              </TableCell>
              <TableCell align="right">
                {data[uid].TakeABreakCurse || 0}
              </TableCell>
              <TableCell align="right">
                {data[uid].Inconvenienced || 0}
              </TableCell>
              <TableCell align="right">
                {data[uid].WentToParliamentCurse || 0}
              </TableCell>
              <TableCell align="right">
                {data[uid].VetoSingleChallenge || 0}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default PlayersDetailView;
