import React from "react";
import "../DashboardPages/Pages.css"; // Create a CSS file for the animations
import waiting from "../../assets/waiting.png";
import { Typography } from "@mui/material";
const WaitingHost = () => {
  return (
    <div className="gameOverScreen">
      <div className="crownImageContainer">
        <img className="crownImage" src={waiting} alt="waiting" />
      </div>
      <Typography sx={{fontFamily: "inherit", paddingLeft: "10px", paddingRight: "10px", color: "white"}} variant="h3">Waiting on Players Making Moves to Load Stats</Typography>

    </div>
  );
};

export default WaitingHost;
