import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import {
  GameButton,
  RoundedCard,
  RoundedTextField,
} from "../MUI_Components/Components";

const authErrors = {
  "auth/invalid-email": "Email address not valid",
  "auth/wrong-password": "Incorrect username or password",
};

export default function SignIn() {
  const { login } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  async function handleSubmit(e) {
    e.preventDefault();
    const data = new FormData(e.currentTarget);

    if (data.get("email") === "" || data.get("email") === "") {
      setError("You must enter an email and password");
      return;
    }
    try {
      setError("");
      setLoading(true);
      await login(data.get("email"), data.get("password"));
      navigate("/");
    } catch (err) {
      console.log(err.code);
      if (authErrors[err.code]) {
        setError(authErrors[err.code]);
      } else {
        setError("Failed to log in");
      }
    }
    setLoading(false);
  }

  return (
    <RoundedCard>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#A78295" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          {error && (
            <Alert severity="error" sx={{ marginTop: "5px" }}>
              {error}
            </Alert>
          )}
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <RoundedTextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <RoundedTextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <GameButton
                type="submit"
                fullWidth
                variant="contained"
                disabled={loading}
              >
                Sign In
              </GameButton>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <GameButton
                fullWidth
                variant="contained"
                disabled={loading}
                onClick={() => {
                  navigate("/register");
                }}
              >
                Register for 2024!
              </GameButton>
            </div>

            <Grid container sx={{ marginTop: "20px" }}>
              <Grid item xs>
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => navigate("/forgot-password")}
                >
                  Forgot password
                </Link>
              </Grid>
              <Grid item>
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => navigate("/signup")}
                >
                  {"Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </RoundedCard>
  );
}
