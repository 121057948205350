import { Divider, Typography } from "@mui/material";
import React, { useState } from "react";
import CountdownTimer from "./CountdownTimer";
import { useAuth } from "../../../../contexts/AuthContext";
import {
  GameButton,
  GreenGameButton,
  RedGameLoadingButton,
} from "../../../MUI_Components/Components";
import { endGame, futureTime } from "../../../../utils/utils";
import { startGame } from "../../../../firebase";
import GMComponent from "../GMProfile/GMComponent";
import DurationPicker from "./DurationPicker";

const GameConfiguration = (props) => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [packName, setPackName] = useState("original");
  const [hours, setHours] = useState(5);
  const [minutes, setMinutes] = useState(0);
  const [hideMins, setHideMins] = useState(30);

  const handleChange = (event) => {
    setPackName(event.target.value);
  };

  async function handleStartGame() {
    props.setError("");
    try {
      console.log(props.user.game, futureTime(hours, minutes), packName);
      await startGame(
        props.user.game,
        futureTime(hours, minutes),
        packName,
        hideMins
      );
      props.setGameEnded(null);
    } catch (error) {
      console.log("error", error);
      props.setError("Failed to Start Game");
    }
  }

  return (
    <>
      {props?.user?.game &&
        currentUser?.uid === props?.host &&
        props?.value &&
        props?.endTime && (
          <>
            {props?.gameStatus === "true" && (
              <CountdownTimer
                endTime={props?.endTime}
                hideLeaderboardMins={props.hideLeaderboardMins}
                sx={{ marginBottom: "30px" }}
              />
            )}
            {props?.gameStatus === "false" && (
              <div style={{ width: "100%" }}>
                <DurationPicker
                  hours={hours}
                  minutes={minutes}
                  setHours={setHours}
                  setMinutes={setMinutes}
                  hideMins={hideMins}
                  setHideMins={setHideMins}
                  packName={packName}
                  packList={props?.packList}
                  handleChange={handleChange}
                />
              </div>
            )}
            <Divider />

            {props?.gameStatus === "false" && (
              <GreenGameButton
                fullWidth
                variant="contained"
                onClick={handleStartGame}
                sx={{ marginTop: "10px" }}
                color="success"
                disabled={props.gameStarted === true}
              >
                Start Game
              </GreenGameButton>
            )}
            {props?.gameStatus === "ended" && (
              <>
                <Typography variant="h4">Game Over!</Typography>
                <GameButton
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    props.handleViewResults();
                  }}
                  sx={{ marginTop: "10px", height: "80px" }}
                >
                  View Results
                </GameButton>
              </>
            )}
            {(props?.gameStatus === "false" ||
              props?.gameStatus === "ended") && (
              <RedGameLoadingButton
                fullWidth
                loading={loading}
                variant="contained"
                onClick={props.handleClickOpen}
                sx={{ marginTop: "30px" }}
                color="error"
              >
                Close Game
              </RedGameLoadingButton>
            )}

            <br />
            {/* GM Components List */}
            <GMComponent gameStarted={props.gameStarted} user={props.user} />
            {props?.gameStatus === "true" && (
              <RedGameLoadingButton
                fullWidth
                variant="contained"
                loading={loading}
                onClick={() => {
                  setLoading(true);
                  endGame(props.user.game);
                }}
                sx={{ marginTop: "30px" }}
                color="error"
              >
                End Game
              </RedGameLoadingButton>
            )}
          </>
        )}
    </>
  );
};

export default GameConfiguration;
