import { onValue, ref } from "firebase/database";
import React, { useEffect, useState } from "react";
import { getDB } from "../../../firebase";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Avatar,
  Button,
  FormControlLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

const LeaderboardGM = (props) => {
  const [leaderboard, setLeaderboard] = useState([]);
  const [images, setImages] = useState([]);
  const [members, setMembers] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [coins, setCoins] = useState(null);
  const handleClickOpen = (type) => {
    setSelectedPlayer(null);
    setOpen(type);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePlayer = (event) => {
    setSelectedPlayer(event.target.value);
  };

  function handleModifyCoins() {
    handleClose();
    setSelectedPlayer(null);

    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `https://modifyplayercoins-nykygdtd7a-uc.a.run.app?playerUID=${selectedPlayer}&gameID=${props.game}&value=${coins}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => printRes(result))
      .catch((error) => console.log("error", error));
  }

  function printRes(res) {
    console.log(res);
  }

  function convertObjectToArray(inputObj) {
    // Initialize an empty array to store the result
    let resultArray = [];

    // Loop through each key-value pair in the input object
    for (const [key, value] of Object.entries(inputObj)) {
      // Create a new object with "name" and "coins" properties
      let newObj = {
        name: key,
        coins: value.coins,
      };

      // Push the new object to the result array
      resultArray.push(newObj);
    }

    // Sort the result array by "coins" property in descending order
    resultArray.sort((a, b) => b.coins - a.coins);

    return resultArray;
  }

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      uids: leaderboard.map((item) => item.name),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://getuserimages-nykygdtd7a-uc.a.run.app", requestOptions)
      .then((response) => response.text())
      .then((result) => setImages(JSON.parse(result)))
      .catch((error) => console.log("error", error));
  }, [leaderboard]);

  useEffect(() => {
    if (props.game !== "false") {
      console.log("running");
      const query = ref(getDB(), "/games/" + props.game);
      return onValue(query, (snapshot) => {
        const data = snapshot.val();
        if (snapshot.exists()) {
          setMembers(data.players);
          console.log("members", data.players);
          console.log("lead", data.leaderboard);
          if (data.leaderboard) {
            setLeaderboard(convertObjectToArray(data.leaderboard));
          }
          console.log(data.players);
        }
      });
    }
  }, []);

  return (
    <div style={{ padding: "2rem" }}>
      <Paper elevation={3} style={{ margin: "0 auto", marginTop: 0 }}>
        <Typography variant="h5" style={{ textAlign: "center", padding: 20 }}>
          Leaderboard
        </Typography>
        <List>
          {leaderboard.map((user, index) => (
            <ListItem key={index} divider>
              <ListItemAvatar>
                <Avatar src={images[user.name]} />
              </ListItemAvatar>
              <ListItemText
                primary={members[user.name].username}
                secondary={`${user.coins} coins`}
              />
            </ListItem>
          ))}
        </List>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Button
            variant="contained"
            sx={{ margin: "4px" }}
            color="success"
            onClick={() => {
              handleClickOpen("Add");
            }}
          >
            Add Player Coins
          </Button>
          <Button
            variant="contained"
            sx={{ margin: "4px" }}
            color="error"
            onClick={() => {
              handleClickOpen("Subtract");
            }}
          >
            Subtract Player Coins
          </Button>
        </div>
      </Paper>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{open} Player Coins</DialogTitle>
        <DialogContent sx={{ padding: "4rem" }}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            onChange={handleChangePlayer}
          >
            {leaderboard &&
              leaderboard.length > 0 &&
              leaderboard.map((member, index) => (
                <FormControlLabel
                  key={index}
                  value={member.name}
                  control={<Radio />}
                  label={members[member.name].username}
                  onChange={(e) => {
                    setSelectedPlayer(e.target.value);
                  }}
                />
              ))}
          </RadioGroup>
          <TextField
            type="number"
            defaultValue={0}
            label={"Number of Coins to " + open}
            sx={{ marginTop: "20px" }}
            onChange={(e) => {
              open === "Add"
                ? setCoins(e.target.value)
                : setCoins(e.target.value * -1);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {open === "Add" && (
            <Button
              variant="contained"
              onClick={handleModifyCoins}
              color="success"
            >
              Add
            </Button>
          )}
          {open === "Subtract" && (
            <Button
              variant="contained"
              onClick={handleModifyCoins}
              color="error"
            >
              Subtract
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LeaderboardGM;
