import { Avatar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getUserData } from "../../firebase";
import "./ControlGameUserTab.css";
import ChallengeCard from "./ChallengeCardGM";
import { styled } from "@mui/system";
import wrinkledPaperImg from "../../assets/wrinkledPaper.jpg";
import coinsImage from "../../assets/coins.png";
import curseCardBackground from "../../assets/curseBackground.png";

const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const WrinkledCard = styled("div")({
  background: `url(${wrinkledPaperImg})`,
  backgroundColor: "white",
  color: "black",
  backgroundSize: "cover",
  border: "1px solid #ccc",
  borderRadius: "8px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
  transform: (props) =>
    `rotate(${getRandomInt(-5, 5)}deg) scale(${getRandomInt(95, 105) / 100})`,
});

const CompletedWrinkledCard = styled("div")({
  background: `url(${wrinkledPaperImg})`,
  color: "gray",
  backgroundSize: "cover",
  border: "1px solid #ccc",
  borderRadius: "8px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
  opacity: "0.5",
  transform: (props) =>
    `rotate(${getRandomInt(-5, 5)}deg) scale(${getRandomInt(95, 105) / 100})`,
});

const CurseCardDiv = styled("div")({
  background: `url(${curseCardBackground})`,
  backgroundColor: "#DBA5EB",
  color: "black",
  backgroundSize: "cover",
  borderRadius: "8px",
  padding: "16px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
  transform: (props) =>
    `rotate(${getRandomInt(-5, 5)}deg) scale(${getRandomInt(95, 105) / 100})`,
});

const ControlGameUserTab = (props) => {
  const { player, game, code } = props;
  const [playerInfoFromGame, setPlayerInfoFromGame] = useState();
  const [userImage, setUserImage] = useState();
  const [currentChallenges, setCurrentChallenges] = useState([]);
  const [completedChallenges, setCompletedChallenges] = useState([]);
  const [completedChallengesApproved, setCompletedChallengesApproved] =
    useState([]);

  useEffect(() => {
    async function getUserDetails() {
      try {
        const userData = await getUserData(player.uid);
        console.log("now here", userData.name);
        setPlayerInfoFromGame(userData);
        setUserImage(userData.image);
        setCurrentChallenges(
          Object.values(
            convertObjectToArrayWithId(player.currentChallenges)
          ).filter((item) => item !== null)
        );
        setCompletedChallenges(
          Object.values(
            convertObjectToArrayWithId(player.completedChallenges)
          ).filter(
            (item) =>
              item !== null &&
              (item.approvedByGM === false || !item.approvedByGM)
          )
        );
        setCompletedChallengesApproved(
          Object.values(
            convertObjectToArrayWithId(player.completedChallenges)
          ).filter((item) => item !== null && item.approvedByGM === true)
        );
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }
    getUserDetails().then((res) => {
      console.log("res", res);
    });
  }, [player.completedChallenges, player.currentChallenges, player.uid]);

  function convertObjectToArrayWithId(obj) {
    return Object.keys(obj).map((key) => ({
      ...obj[key],
      challengeId: parseInt(key),
    }));
  }

  async function approveChallenge(challengeId) {
    // Assuming your Firebase Cloud Function endpoint is named `approveChallenge`
    // and it's hosted on the default Firebase domain

    const endpoint = "https://approvechallenge-nykygdtd7a-uc.a.run.app";

    const data = {
      gameId: code,
      playerId: player.uid,
      challengeId,
    };

    fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  return (
    <div className="tabPage">
      <div className="titleBarUser">
        <div className="playerAvatar">
          {player.profile !== null ? (
            <Avatar
              src={userImage}
              alt={player.username}
              sx={{ width: 100, height: 100, alignItems: "center" }}
            >
              {player.username ? player.username.substring(0, 1) : ""}
            </Avatar>
          ) : (
            <Avatar
              alt="profile avatar"
              sx={{ width: 100, height: 100, alignItems: "center" }}
            >
              {player.username ? player.username.substring(0, 1) : ""}
            </Avatar>
          )}
        </div>

        <div className="TeamInfo">
          <Typography variant="h2">{player.username}</Typography>
          <div className="teamInfoSub">
            <Typography variant="h5" sx={{ marginRight: "20px" }}>
              Game: {code}
            </Typography>
            <div className="teamInfoCoins">
              <img src={coinsImage} alt="coins" className="coins"></img>

              <Typography variant="h5">{player.coins}</Typography>
            </div>
          </div>
        </div>
      </div>
      {player?.curses?.major &&
        Object.values(player?.curses?.major).length > 0 && (
          <>
            <Typography variant="h5" sx={{ color: "white", marginTop: "15px" }}>
              Curses:
            </Typography>
            <div className="curseContainer">
              {Object.values(player?.curses?.major).map((curse) => {
                return (
                  <CurseCardDiv className="curse">
                    <Typography variant="h5">{curse.curseName}</Typography>
                    <Typography variant="subtitle1">
                      Cursed By: {curse.cursedBy}
                    </Typography>
                  </CurseCardDiv>
                );
              })}
            </div>
          </>
        )}
      <div className="cardContainers">
        <div className="currentCardsContainer">
          <Typography variant="h5" sx={{ textAlign: "center", color: "white" }}>
            Current Cards
          </Typography>
          {currentChallenges?.map((challenge) => {
            return (
              <WrinkledCard
                sx={{
                  marginBottom: "10px",
                  padding: "10px 10px 8px 10px",
                  width: "100%",
                  marginRight: "10px",
                }}
              >
                <ChallengeCard
                  challenge={challenge}
                  completed={false}
                  approved={false}
                  loading={false}
                  checkTimings={false}
                />
              </WrinkledCard>
            );
          })}
        </div>

        <div className="currentCardsContainer">
          <Typography variant="h5" sx={{ textAlign: "center", color: "white" }}>
            Completed Cards
          </Typography>
          {(!completedChallenges || completedChallenges?.length === 0) && (
            <Typography
              variant="subtitle1"
              sx={{ textAlign: "center", color: "white", marginTop: "30px" }}
            >
              No Completed Challenges
            </Typography>
          )}
          {completedChallenges?.map((challenge) => {
            return (
              <WrinkledCard
                sx={{
                  marginBottom: "10px",
                  padding: "10px 10px 8px 10px",
                  width: "100%",
                  marginRight: "10px",
                }}
              >
                <ChallengeCard
                  challenge={challenge}
                  approveChallenge={approveChallenge}
                  completed={true}
                  loading={false}
                  approved={true}
                  checkTimings={false}
                />
              </WrinkledCard>
            );
          })}
        </div>

        <div className="currentCardsContainer">
          <Typography variant="h5" sx={{ textAlign: "center", color: "white" }}>
            Approved Cards
          </Typography>
          {(!completedChallenges ||
            completedChallengesApproved?.length === 0) && (
            <Typography
              variant="subtitle1"
              sx={{ textAlign: "center", color: "white", marginTop: "30px" }}
            >
              No Approved Challenges
            </Typography>
          )}
          {completedChallengesApproved?.map((challenge) => {
            return (
              <CompletedWrinkledCard
                sx={{
                  marginBottom: "10px",
                  padding: "10px 10px 8px 10px",
                  width: "100%",
                  marginRight: "10px",
                }}
              >
                <ChallengeCard
                  challenge={challenge}
                  completed={true}
                  approved={false}
                  loading={false}
                  checkTimings={false}
                />
              </CompletedWrinkledCard>
            );
          })}
        </div>
      </div>
      {/* <br />
      <br />
      game:{JSON.stringify(game)}
      <br />
      <br />
      code:{JSON.stringify(code)}
      <br />
      <br />
      playerInfoFromGame:{JSON.stringify(playerInfoFromGame)}
      <br />
      <br /> */}
    </div>
  );
};

export default ControlGameUserTab;
