import React from "react";
import Signup from "./PreSignInPages/Signup";
import { AuthProvider, useAuth } from "../contexts/AuthContext";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Dashboard from "./Dashboard";
import UpdateProfile from "./SettingsPages/UpdateProfile";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import ForgotPassword from "./PreSignInPages/ForgotPassword";
import SignIn from "./PreSignInPages/Signin";
import GameMaster from "./GameMaster";
import PreviousGames from "./SettingsPages/PreviousGames";
import Home from "./PreSignInPages/Home";
import Registered from "./DashboardPages/ProfilePage/Components/Registered";

function App() {
  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <div
        style={{
          minHeight: "calc(100vh - 56px)",
          backgroundColor: "transparent",
          width: "clamp(200px, 400px, 600px)",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div>
          <Router>
            <AuthProvider>
              <Routes>
                <Route
                  path="/"
                  element={
                    <PrivateRoute>
                      <Dashboard />
                    </PrivateRoute>
                  }
                ></Route>
                <Route
                  path="/update-profile"
                  element={
                    <PrivateRoute>
                      <UpdateProfile />
                    </PrivateRoute>
                  }
                ></Route>
                <Route
                  path="/registered"
                  element={
                    <PrivateRoute>
                      <Registered />
                    </PrivateRoute>
                  }
                ></Route>
                <Route
                  path="/game-master"
                  element={
                    <PrivateRoute>
                      <GameMaster />
                    </PrivateRoute>
                  }
                ></Route>
                <Route
                  path="/previous-games"
                  element={
                    <PrivateRoute>
                      <PreviousGames />
                    </PrivateRoute>
                  }
                ></Route>
                <Route
                  path="/signup"
                  element={
                    <PublicRoute>
                      <Signup />
                    </PublicRoute>
                  }
                ></Route>
                <Route
                  path="/login"
                  element={
                    <PublicRoute>
                      <SignIn />
                    </PublicRoute>
                  }
                ></Route>
                <Route path="/register" element={<Home />}></Route>
                <Route
                  path="/forgot-password"
                  element={
                    <PublicRoute>
                      <ForgotPassword />
                    </PublicRoute>
                  }
                ></Route>
                <Route path="*" element={<RedirectToCorrectPage />}></Route>
              </Routes>
            </AuthProvider>
          </Router>
        </div>
      </div>
    </div>
  );
}

function RedirectToCorrectPage() {
  const { currentUser } = useAuth();
  return currentUser ? <Navigate to="/" /> : <Navigate to="/login" />;
}

export default App;
