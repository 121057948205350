import { Alert, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./JoinGame.css";
import { useNavigate } from "react-router-dom";
import {
  cancelGMRequest,
  getDB,
  isGameStarted,
  joinGameAsGM,
} from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { onValue, ref } from "firebase/database";
import gm from "../../assets/GM_Logo.png";
import { GameButton, RoundedCard } from "../MUI_Components/Components";
const JoinGame = (props) => {
  const navigate = useNavigate();
  const [code, setCode] = useState();
  const [error, setError] = useState("");
  const { currentUser } = useAuth();
  const [user, setUser] = useState();
  const [hasRequested, setHasRequested] = useState(false);

  useEffect(() => {
    const query = ref(getDB(), "/users/" + currentUser.uid);
    return onValue(query, (snapshot) => {
      const data = snapshot.val();

      if (snapshot.exists()) {
        setUser(data);
      }
    });
  }, []);

  function joinGameAsGameMaster() {
    isGameStarted(code.toUpperCase()).then((res) => {
      if (res === false) {
        setError("No Game With That Code Exists");
      } else {
        console.log("code", code);
        setError("");
        joinGameAsGM(currentUser.uid, user.name, code, user.phone);
        setHasRequested(true);
        props.setGameFromChild(code);
      }
    });
  }

  return (
      <RoundedCard >
        <div className="gm_logo_container">
          <img src={gm} alt="Game Master Logo" className="gmLogoMain" />
        </div>
        <div className="joinContainer">
          {!hasRequested && (
            <>
              <Typography variant="h5">Game Code:</Typography>
              {error !== "" && <Alert severity="error">{error}</Alert>}
              <TextField
                label="Code"
                className="joinField"
                sx={{ marginTop: "20px" }}
                onChange={(e) => {
                  setCode(e.target.value);
                }}
              />
              <GameButton
                variant="contained"
                className="joinButton"
                sx={{ marginTop: "20px" }}
                onClick={joinGameAsGameMaster}
              >
                Request to be Game Master
              </GameButton>
            </>
          )}
          {hasRequested && (
            <>
              <Typography variant="h5">Your request is pending...</Typography>
              <GameButton
                variant="contained"
                className="joinButton"
                sx={{ marginTop: "80px" }}
                onClick={() => {
                  setHasRequested(false);
                  cancelGMRequest(currentUser.uid, user.name, code);
                }}
              >
                Cancel Request
              </GameButton>
            </>
          )}
          <div className="joinContainer2">
            <GameButton
              variant="contained"
              className="joinButton"
              sx={{ marginTop: "50px" }}
              onClick={() => {
                navigate("/");
              }}
            >
              Back
            </GameButton>
          </div>
        </div>
      </RoundedCard>
  );
};

export default JoinGame;
