import React, { useEffect, useState } from "react";
import GMRequests from "./GMRequests";
import ApprovedGMList from "./ApprovedGMList";
import { onValue, ref } from "firebase/database";
import { getDB } from "../../../../firebase";

const GMComponent = (props) => {
  const [gmRequests, setGMRequests] = useState([]);
  const [gmReqImages, setGMReqImages] = useState([]);

  function getRequestImages(uids) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      uids,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch("https://getuserimages-nykygdtd7a-uc.a.run.app", requestOptions)
      .then((response) => response.text())
      .then((result) => setGMReqImages(JSON.parse(result)))
      .catch((error) => console.log("error", error));
  }

  useEffect(() => {
    if (props.user.game !== "false") {
      const query = ref(getDB(), "/games/" + props.user.game);
      return onValue(query, (snapshot) => {
        const data = snapshot.val();
        if (snapshot.exists()) {
          if (
            data.gameMasterRequests &&
            Object.keys(data.gameMasterRequests).length > 0
          ) {
            setGMRequests(data.gameMasterRequests);
            getRequestImages(Object.keys(data.gameMasterRequests));
          }
        }
      });
    }
  }, [props.user.game]);

  return (
    <>
      {/* GM Approved List */}
      <ApprovedGMList
        gmReqImages={gmReqImages}
        gmRequests={gmRequests}
        gameStarted={props.gameStarted}
      />
      {/* GM Requests List */}
      <GMRequests
        gmRequests={gmRequests}
        gmReqImages={gmReqImages}
        user={props.user}
      />
    </>
  );
};

export default GMComponent;
